import React from "react";
import { Col, Container, Row } from "reactstrap";

export function Counter({ timerDays, timerHours, timerMinutes, timerSeconds }) {
    return (
        <Container className="  h-50 d-flex flex-column  align-items-center">
            <Row className="h-75 w-100 count-down text-light d-flex justify-content-center align-items-center px-3">
                <Col
                    lg={3}
                    md={6}
                    xs={6}
                    className="back-color d-flex justify-content-center align-items-center h-75"
                >
                    <div className=" d-flex flex-column justify-content-center align-items-center h-100 w-100 text-center">
                        <h1 className="h-100 w-75 d-flex justify-content-center align-items-center text-size">
                            {timerDays}
                        </h1>

                        <h1 className="justify-content-end">DAYS</h1>
                    </div>
                </Col>
                <Col className="back-color d-flex justify-content-center align-items-center h-75">
                    <div className=" d-flex flex-column justify-content-center align-items-center h-100 w-100 text-center">
                        <h1 className="h-100 d-flex justify-content-center align-items-center  text-size">
                            {timerHours}
                        </h1>

                        <h1 className="justify-content-end">HOURS</h1>
                    </div>
                </Col>
                <Col className="back-color d-flex justify-content-center align-items-center h-75">
                    <div className=" d-flex flex-column justify-content-center align-items-center h-100 w-100 text-center">
                        <h1 className="h-100 d-flex justify-content-center align-items-center  text-size">
                            {timerMinutes}
                        </h1>
                        <h1 className="justify-content-end">MINUTES</h1>
                    </div>
                </Col>
                <Col className="back-color d-flex justify-content-center align-items-center h-75">
                    <div className=" d-flex flex-column justify-content-center align-items-center h-100 w-100 text-center ">
                        <h1 className="h-100 d-flex justify-content-center align-items-center text-danger text-size">
                            {timerSeconds}
                        </h1>

                        <h1 className="justify-content-end">SECONDS</h1>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}
Counter.defaultProps = {
    timerDays: 0,
    timerHours: 0,
    timerMinutes: 0,
    timerSeconds: 0,
};
